import { render, staticRenderFns } from "./openstreemap2.vue?vue&type=template&id=19e1b1b3&"
import script from "./openstreemap2.vue?vue&type=script&lang=js&"
export * from "./openstreemap2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19e1b1b3')) {
      api.createRecord('19e1b1b3', component.options)
    } else {
      api.reload('19e1b1b3', component.options)
    }
    module.hot.accept("./openstreemap2.vue?vue&type=template&id=19e1b1b3&", function () {
      api.rerender('19e1b1b3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8mAVdnXFXiKzm/JS/comp/openstreemap2.vue"
export default component.exports